
import React, { useState } from 'react';

const ListLoader = (props) => {
    const [messagelist, setMessagelist] = useState('');
    const [listingPrice, setListingPrice] = useState(0);

    const handlePriceChange = (e) => {
        const userInput = parseFloat(e.target.value);

        if (!userInput || userInput <= 0) {
            props.setErrMessg('Value must be a number and not less than 0');
            setListingPrice(0);
            setMessagelist('');
            return;
        } else {
            props.setErrMessg('');
        }
 
        const auctionFeeRate = 0.01;  
        const creatorFeeRate = 0.07;  
 
        const priceWithAuctionFee = userInput / (1 - auctionFeeRate);
        const calculatedListingPrice = priceWithAuctionFee / (1 - creatorFeeRate);
        const newPrice = calculatedListingPrice.toFixed(3);

        setListingPrice(newPrice);
        setMessagelist(`Fees included: Taker Fee 1%, Creator Royalties 7%. Total ${newPrice} SOL`);
        props.setListingPrice(newPrice);
    };

    return ( 
        <div>
            <div className="full-overlay">
                <div className="list-square">
                    <div className='text-center p-1'>
                        <div className="bg-primary closecross-section"><button onClick={props.closePopupList} style={{float:"right", width:"25px"}}><i className="fa fa-times"></i></button></div>
                        <img src={props.listingURI} alt="nftloader" />
                        <p className="p-para pt-2">{props.listingName}</p>
                        <div className="white-form-group">
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Ask Price?"
                                min="0"
                                onChange={handlePriceChange}
                            />
                            <div className="text-center p-para">{messagelist}</div>
                        </div>
                        <div>
                            <button className="btn-solid-grad" onClick={() => props.listNFT(props.listingNFT)}>List</button>
                        </div>
                        <div className="text-center text-danger">{props.errMessg}</div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default ListLoader;