import poweredBy from '../resources/images/footer/powered-by.svg';
import gmail from '../resources/images/footer/gmail.svg';
import discord from '../resources/images/footer/discord.svg';
import linkedin from '../resources/images/footer/lnkedin.svg';
import twitter from '../resources/images/footer/twitter.svg';
import github from '../resources/images/footer/github.svg';
const Footer = () => {
    return ( 
        <div>
              <footer id="footer" className="footer-light-style clearfix bg-style">
          <div className="themesflat-container">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-12">
                <div className="widget widget-logo">
                  <div className="logo-footer" id="logo-footer">
                    <a href="https://zonexlegacy.com/" style={{ color: 'white', textDecoration: 'none' }}>
                      <img
                        className=""
                        id="logo_footer"
                        src="https://zonexlegacy.com/static/media/logo_dark.972a3e148034d6c70ab1.png"
                        alt="nft-gaming" 
                        style={{ width: '120px' }}
                      /> 
                    </a>
                  </div>
                  <p className="sub-widget-logo">©2024 ZoneX. All Rights Reserved.</p>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-7 col-7">
                <div className="widget widget-menu style-2">
                  <h5 className="title-widget">Company</h5>
                  <ul>
                    <li><a href="https://zonexlegacy.com/about-us" style={{ color: 'white', textDecoration: 'none' }}>About Us</a></li>
                    <li><a href="https://zonexlegacy.com/contact-us" style={{ color: 'white', textDecoration: 'none' }}>Contact Us</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-5 col-5">
                <div className="widget widget-menu style-1">
                  <h5 className="title-widget">Resources</h5>
                  <ul>
                    <li><a href="https://zonexlegacy.com/roadmap" style={{ color: 'white', textDecoration: 'none' }}>Roadmap</a></li>
                    <li><a href="https://zonexlegacy.com/faq" style={{ color: 'white', textDecoration: 'none' }}>FAQ</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-5 col-5">
                <div className="widget widget-menu fl-st-3">
                  <h5 className="title-widget">Legal</h5>
                  <ul>
                    <li><a href="https://zonexlegacy.com/terms-conditions" style={{ color: 'white', textDecoration: 'none' }}>Terms & Conditions</a></li>
                    <li><a href="https://zonexlegacy.com/cookies" style={{ color: 'white', textDecoration: 'none' }}>Cookies</a></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-7 col-12">
                <div className="widget widget-subscribe">
                  <h5 className="title-widget">Socials</h5>
                  <div className="widget-social style-1 mg-t32">
                    <ul>
                      <li>
                        <a href="https://discord.gg/GhfnRX3eMP" style={{ color: 'white', textDecoration: 'none', backgroundColor: 'black' }}><i className="icon-fl-vt"></i></a>
                      </li>
                      <li>
                        <a href="https://twitter.com/ZoneX_NFT" style={{ color: 'white', textDecoration: 'none', backgroundColor: 'black' }}>
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.reddit.com/r/ZoneX_Conspiracies/" style={{ color: 'white', textDecoration: 'none', backgroundColor: 'black' }}>
                          <i className="fab fa-reddit"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/zonex.legacy/" style={{ color: 'white', textDecoration: 'none', backgroundColor: 'black' }}>
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.tiktok.com/@zonex.legacy" style={{ color: 'white', textDecoration: 'none', backgroundColor: 'black' }}>
                          <i className="icon-fl-tik-tok-2"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        </div>
     );
}
 
export default Footer;